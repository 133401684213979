import { useEffect } from 'react';

const useJornaya = () => {
  useEffect(() => {
    window.setLeadId = function (leadId) {
      window.leadId = leadId;
    };

    const insertLeadiDScript = () => {
      if (document.getElementById('LeadiDscript_campaign')) {
        return;
      }

      const script = document.createElement('script');
      script.id = 'LeadiDscript_campaign';
      script.type = 'text/javascript';
      script.async = true;
      script.src =
        '//create.lidstatic.com/campaign/82ffa52e-62d7-f975-7a77-c070b670b583.js?snippet_version=2&callback=setLeadId';

      const LeadiDscript = document.getElementById('LeadiDscript');
      LeadiDscript.parentNode.insertBefore(script, LeadiDscript);
    };

    const mainScript = document.createElement('script');
    mainScript.id = 'LeadiDscript';
    mainScript.type = 'text/javascript';
    mainScript.defer = true;
    mainScript.innerHTML = `(${insertLeadiDScript.toString()})()`;

    document.body.appendChild(mainScript);

    return () => {
      document.body.removeChild(mainScript);
      const scriptCampaign = document.getElementById('LeadiDscript_campaign');
      if (scriptCampaign) {
        scriptCampaign.parentNode.removeChild(scriptCampaign);
      }

      delete window.setLeadId;
    };
  }, []);

  return null;
};

export default useJornaya;
