import {
  PROJECT_TYPE_ERROR_MESSAGE,
  RADIO_ERROR_MESSAGE,
  ROOF_OPTIONS,
  ROOF_PROJECT_OPTIONS,
} from '../../../lib/constants';

import { CustomSelect } from '../../CustomComponents';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useRef } from 'react';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep2() {
  const { register, trigger, handleSubmit, formState, setValue, clearErrors } =
    useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const { errors } = formState;
  const roofMaterialRef = useRef();
  const projectTypeRef = useRef();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['projecttype', 'roofmaterial']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        roofmaterial: roofMaterialRef,
        projecttype: projectTypeRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  const handleClick = (event) => {
    event.stopPropagation();
    if (event.target.type !== 'radio') {
      const input = event.currentTarget.querySelector('input[type="radio"]');
      input.click();
    }
  };

  useScrollToTop();
  return (
    <div className="form-step2">
      <div className="form-step">
        <div className="form-step__label">What Kind Of Roof Do You Have? </div>

        <CustomSelect
          options={ROOF_OPTIONS}
          name="roofmaterial"
          value={formData.roofmaterial}
          register={register}
          onChange={updateFormData}
          errorMessage={PROJECT_TYPE_ERROR_MESSAGE}
          errors={errors}
          customRef={roofMaterialRef}
        />

        <div className="form-step__label">
          Are You Looking To Repair Or Replace Your Roof?
        </div>

        <div className="form-step__input-container">
          {ROOF_PROJECT_OPTIONS.map(
            ({ id, value, textOption, registerLabel }, index) => (
              <div
                key={`${id}-${index}`}
                className={`form-step__input form-step__input-no-background ${
                  errors[registerLabel] && 'form-step__error'
                }`}
                onClick={handleClick}
              >
                <div className="form-step__input-radio-wrapper">
                  <input
                    type="radio"
                    value={value}
                    id={id}
                    ref={projectTypeRef}
                    {...register(registerLabel, {
                      required: PROJECT_TYPE_ERROR_MESSAGE,
                    })}
                    onChange={(e) => {
                      updateFormData({ projecttype: e.target.value });
                      setValue(registerLabel, e.target.value);
                    }}
                  />
                  <label htmlFor={id}>{textOption}</label>
                </div>
              </div>
            )
          )}
          {errors.homeowner && (
            <div className="form-step__error-message">
              {RADIO_ERROR_MESSAGE}
            </div>
          )}
        </div>

        <input
          onClick={handleClickContinue}
          className="form-step__btn"
          type="button"
          value="Continue"
        />

        <input
          onClick={() => {
            clearErrors();
            updateStep(formData.step - 1);
          }}
          className="form-step__btn-back"
          type="button"
          value="Back"
        />
      </div>
      <div className="hintArea">
        <div className="hint__title">Why do we ask?</div>
        <div className="hint__text">
          This information helps local professionals to provide you with the
          most accurate quote.
        </div>
      </div>
    </div>
  );
}

export default FormStep2;
