export const DEFAULT_MAIN_TITLE = 'Save On Replacing Or Repairing Your Roofing';

export const DEFAULT_MAIN_SUBTITLE =
  'Find Out How Much You Can Save In Just 30 Seconds!';

export const EXIT_PAGE_MESSAGE =
  'Are You Sure You Want To Leave? There Is So Much More To Explore!';

export const ZIP_CODE_ERROR_MESSAGE = 'Please Enter A Valid Zip Code';

export const RADIO_ERROR_MESSAGE = 'Please Select An Option';

export const FIRST_NAME_ERROR_MESSAGE = 'Please Enter First Name';

export const PROJECT_TYPE_ERROR_MESSAGE = 'Please Select A Roof Type Option';

export const LAST_NAME_ERROR_MESSAGE = 'Please Enter Last Name';

export const ADDRESS_ERROR_MESSAGE = 'Please Enter Street Address';

export const ADDRESS_PATTERN_ERROR_MESSAGE =
  'Please Do Not Put Your Email Address In This Field';

export const INVALID_STARTING_DIGITS_ERROR_MESSAGE =
  'Please do not use numbers starting with 0 or 1';

export const CITY_ERROR_MESSAGE = 'Please Enter A City';

export const STATE_ERROR_MESSAGE = 'Please Select A State';

export const EMAIL_ERROR_MESSAGE = 'Please Enter A Valid Email';

export const EMAIL_DOMAIN_ERROR_MESSAGE = 'Please Verify Your Email Domain';

export const PHONE_ERROR_MESSAGE =
  'Please Enter A Valid Phone Number In The Format (xxx) xxx-xxxx';

export const PATTERN_PHONE_ERROR_MESSAGE =
  'Please Do Not Start A Phone Number With 0 or 1';

export const TOLL_FREE_PHONE_ERROR_MESSAGE =
  'Toll Free Area Codes Are Not Allowed';

export const CONSECUTIVE_PHONE_NUMBER_ERROR_MESSAGE =
  'Please Input A Valid Phone Number';

export const NETWORK_ERROR = 'Network Response Was Not Ok';

export const LOCAL_PHONE_NUMBER = '877-621-3085';
